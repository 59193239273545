<template>
  <v-container fluid grid-list-md>
    <v-layout justify-center>
      <v-flex md8>
        <v-toolbar flat dense
          ><v-toolbar-title style="font-size: 1.8em">
            Cadastros
          </v-toolbar-title>
        </v-toolbar>
        <v-layout row wrap align-center>
          <v-flex v-for="(item, i) in items" :key="i">
            <v-card
              :style="'border: 1px solid LightGrey'"
              :to="item.to"
              class="mx-auto"
              max-width="400"
              max-height="80"
              hover
              v-if="mixPermissao($options.name, item.name)"
            >
              <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                  <v-card-title
                    class="text-h5"
                    v-text="item.title"
                  ></v-card-title>
                  <v-card-subtitle
                    class="subheading grey--text text--darken-1"
                    v-text="item.subtitle"
                  ></v-card-subtitle>
                </div>

                <v-icon class="mr-4" x-large :color="item.icolor">
                  {{ item.icon }}
                </v-icon>
              </div>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import PermissoesMixin from "@/mixins/PermissoesMixin";

export default {
  name: "Cadastros",
  mixins: [PermissoesMixin],
  data: () => ({
    items: [
      {
        to: "/CadRotasLista",
        icon: "mdi-routes",
        icolor: "light-green",
        title: "Rotas",
        subtitle: "Rotas",
        name: "rotas",
      },
      {
        to: "/CadDespachantesLista",
        icon: "mdi-account-tie",
        icolor: "blue",
        title: "Despachantes",
        subtitle: "Despachantes e ajudantes",
        name: "despachantes",
      },
      {
        to: "/CadTransportadoresLista",
        icon: "mdi-clipboard-account-outline",
        icolor: "lime",
        title: "Transportadores",
        subtitle: "Transportadores",
        name: "transportadores",
      },
      {
        to: "/CadProprietariosLista",
        icon: "mdi-briefcase-account-outline",
        icolor: "deep-purple",
        title: "Proprietários",
        subtitle: "Proprietários",
        name: "proprietarios",
      },
      {
        to: "/CadMotoristasLista",
        icon: "mdi-card-account-details-outline",
        icolor: "teal",
        title: "Motoristas",
        subtitle: "Motoristas",
        name: "motoristas",
      },
      {
        to: "/CadEmpresasLista",
        icon: "mdi-domain",
        icolor: "indigo",
        title: "Empresas",
        subtitle: "Empresas",
        name: "empresas",
      },
      {
        to: "/CadPermissosLista",
        icon: "mdi-numeric",
        icolor: "cyan",
        title: "Permissos",
        subtitle: "Numeração utilizada em CRTs e MICs",
        name: "permissos",
      },
      {
        to: "/CadNumeracoesLista",
        icon: "mdi-counter",
        icolor: "purple",
        title: "Numerações",
        subtitle: "Controle de numeração automática",
        name: "numeracoes",
      },
      {
        to: "/CadVeiculosLista",
        icon: "mdi-truck-cargo-container",
        icolor: "blue-grey",
        title: "Veículos",
        subtitle: "Veículos",
        name: "veiculos",
      },
      {
        to: "/CadLocaisLista",
        icon: "mdi-google-maps",
        icolor: "green",
        title: "Locais",
        subtitle: "Portos e Alfandêgas",
        name: "locais",
      },
    ],
  }),
};
</script>
